//
// Typography
// --------------------------------------------------


// 404 display heading

.display-404 {
  color: $white;
  text-shadow: -.0625rem 0 var(--#{$prefix}primary), 0 .0625rem var(--#{$prefix}primary), .0625rem 0 var(--#{$prefix}primary), 0 -.0625rem var(--#{$prefix}primary);
}


// Lists

.list-style {
  list-style: none;
  li {
    margin-bottom: $spacer * .4;
    &::before {
      display: inline-block;
      width: 1em;
      margin-left: -1em;
      color: $list-style-color;
      font-weight: bold;
      content: '\2022';
    }
  }
}
ol.list-style {
  counter-reset: li;
  li {
    counter-increment: li;
    &::before {
      margin: {
        right: .5em;
        left: -1.5em;
      }
      font-weight: inherit;
      text-align: right;
      direction: rtl;
      white-space: nowrap;
      content: '.' counter(li);
    }
  }
}
.list-unstyled > li {
  margin-bottom: $spacer * .4;
  ul li, ol li {
    margin-bottom: $spacer * .333;
  }
}
.list-inline-item:not(:last-child) {
  margin-right: $spacer;
}


// Description list

dt {
  color: $headings-color;
  margin-bottom: $spacer * .25;
  font-weight: $headings-font-weight;
}
dd {
  font-size: $font-size-sm;
  margin-bottom: $spacer;
}


// Blockquote

.blockquote-footer {
  margin-top: -($blockquote-margin-y * .5);
}
